<script>
import { reactive, ref } from 'vue'
import { userService } from '@/helpers/authservice/user.service'
import Swal from "sweetalert2"
import router from '@/router'

export default {
  setup() {
    const form = reactive({
      nome: '',
      email: '',
      conta: '',
    })

    const loading = ref(false)

    /**
     * Get form data and send to api
     * If got some error, show alert errors
     */
    async function handleRegisterUser() {
      if (loading.value) {
        return false
      }

      loading.value = true
      const errors = await userService.register(form)

      if (errors.data) {
        loading.value = false
        Swal.fire({
          icon: "success",
          text: 'Cadastro efetuado com sucesso!',
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
          .then(() => {
            router.replace({
              name: 'signin-basic'
            })
          })
      } else {
        loading.value = false
        Swal.fire({
          icon: "error",
          text: errors,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      }
      
    }

    return {
      form,
      handleRegisterUser,
      loading
    }

  },
  mounted() {
    

    let nameInput = document.getElementById("name")
    nameInput.onkeyup = (e) => {
      if (e.target.value.length == 0) {
        return
      }
      let replacedName = e.target.value.replace(/[^ A-Za-zÀ-ÖØ-öø-ÿ]/g, '')
      replacedName = replacedName.replace('  ', ' ')
      nameInput.value = replacedName[0].toUpperCase() + replacedName.substring(1)
    }
  },
}
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay" />

      <div class="shape">

        <svg version="1.1" viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
        </svg>
      </div>

    </div>

    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <router-link to="/" class="d-inline-block auth-logo">
                <img src="@/assets/images/logos/ApoioBranco.png" alt="" height="100">
              </router-link>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">
                    Registrar uma conta nova
                  </h5>
                </div>
                <div class="p-2 mt-4">
                  <form @submit.prevent="handleRegisterUser" class="needs-validation">
                    <div class="mb-3">
                      <label for="name" class="form-label">
                        Nome Completo
                        <span class="text-danger">*</span>
                      </label>
                      <input v-model="form.nome" type="text" class="form-control" id="name" placeholder="Coloque seu nome"
                        required>
                    </div>
                    <div class="mb-3">
                      <label for="useremail" class="form-label">
                        Email
                        <span class="text-danger">*</span>
                      </label>
                      <input v-model="form.email" type="email" class="form-control" id="useremail"
                        placeholder="Coloque seu email" required>
                    </div>
                    <div class="mb-3">
                      <label for="username" class="form-label">
                        Conta
                        <span class="text-danger">*</span>
                      </label>
                      <input v-model="form.conta" type="text" class="form-control" id="username"
                        placeholder="Coloque sua conta" required>
                    </div>

                    <div class="mt-4">
                      <div v-if="loading" class="d-flex justify-content-center">
                        <b-spinner variant="success"></b-spinner>
                      </div>
                      <button v-if="!loading" class="btn btn-success w-100" type="submit">
                        Registrar
                      </button>
                    </div>
                    <div class="mt-4 text-center"></div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Já possui uma conta?
                <router-link to="/login" class="fw-semibold text-primary text-decoration-underline">
                  Entrar
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                {{new Date().getFullYear()}} © Apoio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>